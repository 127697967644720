<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20 white--text">
      {{ $t("account_settings") }}
    </div>
    <div class="d-flex flex-column mt-5">
      <v-card class="w-100">
        <v-card-title>
          {{ $t("account_information") }}
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="d-flex align-center">
            <div
              class="mr-2 mb-1 dark-font-color app-medium-font"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              {{ $vuetify.breakpoint.xs ? "Email" : "Email address" }}
            </div>
            <v-tooltip bottom v-if="profile.email_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#0A5B8A" size="14">
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>Verified email</span>
            </v-tooltip>
            <div
              class="mb-1 ml-5"
              v-else
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              Not verified<v-btn
                text
                :small="$vuetify.breakpoint.smAndUp"
                :x-small="$vuetify.breakpoint.xs"
                class="ml-2"
                @click="verifyEmail"
                :disabled="disableResendEmail"
                :loading="sendingEmail"
              >
                {{
                  resendEmailCount
                    ? "Wait " +
                      resendEmailCount +
                      "s to resend verification email"
                    : "Verify Now"
                }}
              </v-btn>
            </div>
          </div>
          <v-text-field
            v-model="email"
            outlined
            dense
            placeholder="Email"
            @input="onChange"
            :rules="[rules.required, rules.email]"
          />
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="d-flex align-center">
            <div
              class="mr-2 mb-1 dark-font-color app-medium-font"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              {{ $vuetify.breakpoint.xs ? "Phone" : "Phone number" }}
            </div>
            <v-tooltip bottom v-if="profile.phone_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#0A5B8A" size="14">
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>Verified phone number</span>
            </v-tooltip>
            <div
              class="mb-1 ml-5"
              v-else
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
            >
              Not verified
              <v-btn
                text
                :small="$vuetify.breakpoint.smAndUp"
                :x-small="$vuetify.breakpoint.xs"
                class="ml-2"
                :disabled="disableResendSMS"
                @click="verifyPhone"
                :loading="sendingCode"
                >Verify Now</v-btn
              >
            </div>
          </div>
          <AppPhoneNumberInput
            v-model="phone"
            @input="onChange"
            @update="onUpdatePhone"
          />
        </v-card-text>
        <v-card-text v-if="errorMessage">
          <v-alert
            dense
            type="error"
            v-text="errorMessage"
            v-if="errorMessage"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-5 mb-3">
          <v-btn color="primary" @click="onSave" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="w-100 mt-5" v-if="profileType == 'Volunteer'">
        <v-card-title>
          {{ $t("account_privacy") }}
        </v-card-title>
        <v-card-text class="pb-0 d-flex mb-10">
          <div>
            Who can see your profile?
          </div>
          <div class="ml-3" @click="editSharePrivacy">
            {{ profile_share_privacy }}
          </div>
          <v-btn
            icon
            class="ml-2"
            x-small
            color="#0A5B8A"
            @click="editSharePrivacy"
          >
            <v-icon>
              {{
                profile_share_privacy
                  ? shareIcons[profile_share_privacy]
                  : "mdi-lock"
              }}
            </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card class="w-100 mt-5" v-if="profileType == 'Agency'">
        <v-card-title>
          Balance
        </v-card-title>
        <v-card-text class="pb-0 d-flex mb-10 flex-column">
          <div class="mb-3">
            View and manage the balance of donations received through our
            platform. Connect your bank account to receive payouts, and track
            the funds you've raised to support your cause.
          </div>
          <div v-if="profile.stripe_account && balance">
            <div v-if="balance.available">
              Available:
              <span class="font-20"
                >{{
                  formatCurrency(
                    balance.available.reduce((a, b) => {
                      return a + b.amount;
                    }, 0) / 100
                  )
                }}
              </span>
            </div>
            <div
              v-if="
                balance.pending &&
                  balance.pending.reduce((a, b) => {
                    return a + b.amount;
                  }, 0)
              "
            >
              Pending:
              {{
                formatCurrency(
                  balance.pending.reduce((a, b) => {
                    return a + b.amount;
                  }, 0) / 100
                )
              }}
            </div>
            <div v-if="balance.connect_reserved">
              Reserved:
              {{
                formatCurrency(
                  balance.connect_reserved.reduce((a, b) => {
                    return a + b.amount;
                  }, 0) / 100
                )
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-end mx-5 mb-3"
          v-if="!profile.stripe_account"
        >
          <v-btn
            color="primary"
            @click="onConnectBalance"
            :loading="connectingBalance"
          >
            Connect Balance
          </v-btn>
        </v-card-actions>
        <v-card-actions class="d-flex justify-end mx-5 mb-3" v-else>
          <v-btn color="primary" @click="goToDonations">
            View details
          </v-btn>
          <v-btn
            color="primary"
            @click="goToOnboarding"
            :loading="connectingBalance"
          >
            Go to onboarding
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="w-100 mt-5"
        v-if="profileType == 'Volunteer' || profileType == 'Agency'"
      >
        <v-card-title>
          {{ $t("linked_accounts") }}
        </v-card-title>
        <v-card-text class="pb-0 d-flex mb-10 flex-column">
          <div class="mb-3">
            You can review connected social accounts here.
          </div>
          <v-row>
            <v-col cols="6">
              <v-btn @click="authenticate('facebook')" block depressed>
                <v-icon class="mr-3">mdi-facebook</v-icon>
                Facebook
                <v-icon class="ml-5" v-if="profile.facebook_access_token">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="authenticate('instagram')" block depressed>
                <v-icon class="mr-3">mdi-instagram</v-icon>Instagram
                <v-icon class="ml-5" v-if="profile.instagram_access_token">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="authenticate('linkedin')" block depressed>
                <v-icon class="mr-3">mdi-linkedin</v-icon>LinkedIn
                <v-icon class="ml-5" v-if="profile.linkedin_access_token">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="authenticate('twitter')" block depressed>
                <v-icon class="mr-3">mdi-twitter</v-icon>Twitter
                <v-icon class="ml-5" v-if="profile.twitter_access_token">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="w-100 mt-5">
        <v-card-title>
          {{ $t("delete_account") }}
        </v-card-title>
        <v-card-text class="pb-0">
          This is permanent. When you delete your aKinder™Volunteer account, you
          won't be able to retrieve the content or information you've shared on
          aKinder™Volunteer.
        </v-card-text>
        <v-card-text v-if="confirmDelete">
          Please type <span class="app-bold-font">DELETE</span> to confirm your
          deletion.
          <v-text-field
            v-model="confirmDeleteText"
            outlined
            dense
            placeholder="DELETE"
            @input="onChange"
            class="mt-1"
            :error-messages="errorDelete"
          />
        </v-card-text>
        <v-card-text v-if="errorDeleteMessage">
          <v-alert
            dense
            type="error"
            v-text="errorDeleteMessage"
            v-if="errorDeleteMessage"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-5 mb-3 mt-5">
          <v-btn
            color="red"
            @click="onDeleteAccount"
            :loading="deletingAccount"
            dark
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <phone-verification-dialog
        :verifyDialog="verifyPhoneDialog"
        :onClose="onCloseVerify"
        v-if="verifyPhoneDialog"
      />
      <volunteer-profile-edit-audience
        :dialog="audienceDialog"
        type="profile"
        :onClose="onClosePrivacyDialog"
        v-if="audienceDialog"
      />
      <ConfirmDeleteDialogVue
        v-if="disconnectDialog"
        text="Are you sure do you want to disconnect?"
        :on-close="onCloseDisconnectDialog"
        :on-confirm="onConfirmDisconnect"
        :deleteDialog="disconnectDialog"
        delete-button-text="Yes, Disconnect it"
      />
    </div>
  </div>
</template>
<script>
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";
import PhoneVerificationDialog from "@/components/common/PhoneVerificationDialog.vue";
import VolunteerProfileEditAudience from "../volunteer/profile/edit/VolunteerProfileEditAudience";
import { mapActions, mapGetters } from "vuex";
import ConfirmDeleteDialogVue from "../../components/common/ConfirmDeleteDialog.vue";

export default {
  components: {
    AppPhoneNumberInput,
    PhoneVerificationDialog,
    VolunteerProfileEditAudience,
    ConfirmDeleteDialogVue,
  },
  data() {
    return {
      email: "",
      phone: "",
      profile_share_privacy: "",
      phonePayload: {},
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      shareIcons: {
        "Only me": "mdi-lock",
        "Only agencies I fanned": "mdi-domain",
        "Only followed volunteers": "mdi-account-multiple",
        "All followed agencies and volunteers": "mdi-account-group",
        Public: "mdi-earth",
      },
      loading: false,
      connectingBalance: false,
      sendingEmail: false,
      snackbar: false,
      snackMessage: null,
      errorMessage: null,
      resendEmailCount: 0,
      disableResendEmail: false,
      sendingCode: false,
      disableResendSMS: false,
      verifyPhoneDialog: false,
      confirmDelete: false,
      audienceDialog: false,
      deletingAccount: false,
      confirmDeleteText: "",
      errorDelete: null,
      errorDeleteMessage: null,
      selectedSocialType: "",
      disconnectDialog: false,
      socialConnectedChannel: new BroadcastChannel("social_connected_callback"),
      balance: null,
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
      sendEmailVerification: "auth/sendEmailVerification",
      sendVerificationCode: "auth/sendVerificationCode",
      deleteAccount: "auth/deleteAccount",
      requestTwitterToken: "auth/requestTwitterToken",
      disconnectSocial: "auth/disconnectSocial",
      connectAgencyBalance: "agency/connectBalance",
      getBalance: "agency/getBalance",
      getOnboardingUrl: "agency/createOnboardingUrl",
    }),
    onChange() {
      this.errorMessage = null;
      this.errorDelete = null;
      this.errorDeleteMessage = null;
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    onSave() {
      this.errorMessage = null;
      if (this.email || this.phone) {
        this.loading = true;
        let phone = this.phone;
        if (
          this.phonePayload.isValid &&
          this.phonePayload.countryCallingCode != "1"
        ) {
          phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
        }
        this.updateProfile({ email: this.email, phone })
          .then(() => {
            this.loading = false;
            this.email = this.profile.email;
            this.phone = this.profile.phone;
            let str = "";
            if (!this.profile.email_verified) str = " Please verify your email";
            if (!this.profile.phone_verified)
              str = str
                ? str + " and phone number"
                : "Please verify your phone number";
            this.showSnackMessage(
              "Updated info successfully! " + (str ? str + "." : "")
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
            this.loading = false;
          });
      }
    },
    verifyEmail() {
      if (this.email != this.profile.email) {
        this.errorMessage = "Please save the updated email first.";
        return;
      }
      this.sendingEmail = true;
      this.sendEmailVerification()
        .then(() => {
          this.sendingEmail = false;
          this.disableResendEmail = true;
          this.resendEmailCount = 60;
          this.countDownEmail();
        })
        .catch((error) => {
          console.log(error);
          this.sendingEmail = false;
        });
    },
    verifyPhone() {
      if (this.phone != this.profile.phone) {
        this.errorMessage = "Please save the updated phone number first.";
        return;
      }
      this.sendingCode = true;
      this.sendVerificationCode()
        .then(() => {
          this.sendingCode = false;
          this.verifyPhoneDialog = true;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    editSharePrivacy() {
      this.audienceDialog = true;
    },
    onClosePrivacyDialog() {
      this.audienceDialog = false;
    },
    showSnackMessage(message) {
      this.snackbar = true;
      this.snackMessage = message;
    },
    countDownEmail() {
      if (this.resendEmailCount > 0) {
        setTimeout(() => {
          this.resendEmailCount -= 1;
          this.countDownEmail();
        }, 1000);
      } else {
        this.disableResendEmail = false;
      }
    },
    onCloseVerify() {
      this.verifyPhoneDialog = false;
    },
    onCloseDisconnectDialog() {
      this.disconnectDialog = false;
    },
    onConfirmDisconnect() {
      this.disconnectDialog = false;
      this.disconnectSocial({ provider: this.selectedSocialType });
    },
    onDeleteAccount() {
      if (!this.confirmDelete) {
        this.confirmDelete = true;
        this.confirmDeleteText = "";
      } else {
        if (this.confirmDeleteText == "DELETE") {
          this.deletingAccount = true;
          this.deleteAccount()
            .then(() => {
              this.deletingAccount = false;
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.deletingAccount = false;
              this.errorDeleteMessage = error.response.data.message;
              this.confirmDeleteText = "";
              console.log(error);
            });
        } else if (this.confirmDeleteText == "") {
          this.errorDelete = "Required";
        } else {
          this.errorDelete = "Type correctly";
        }
      }
    },
    authenticate(provider) {
      if (provider == "facebook" && this.profile.facebook_access_token) {
        this.selectedSocialType = provider;
        this.disconnectDialog = true;
      } else if (
        provider == "instagram" &&
        this.profile.instagram_access_token
      ) {
        this.selectedSocialType = provider;
        this.disconnectDialog = true;
      } else if (provider == "linkedin" && this.profile.linkedin_access_token) {
        this.selectedSocialType = provider;
        this.disconnectDialog = true;
      } else if (provider == "twitter" && this.profile.twitter_access_token) {
        this.selectedSocialType = provider;
        this.disconnectDialog = true;
      } else {
        let authorizationEndpoint,
          redirectUri,
          scope,
          scopeDelimiter,
          configId,
          width,
          height,
          left,
          top,
          clientId,
          authUrl,
          extras,
          state;
        switch (provider) {
          case "facebook":
            authorizationEndpoint =
              "https://www.facebook.com/v18.0/dialog/oauth";
            redirectUri = "https://akindervolunteer.com/facebook-callback";
            scope = [
              "email",
              "public_profile",
              "pages_read_engagement",
              "pages_manage_posts",
              // "publish_to_groups",
            ];
            scopeDelimiter = ",";
            configId = process.env.VUE_APP_FACEBOOK_CONFIGURATION_ID;
            width = 564.348;
            height = 670;
            left = window.innerWidth / 2 - width / 2;
            top = window.innerHeight / 2 - height / 2;
            clientId = process.env.VUE_APP_FACEBOOK_ID;
            authUrl = `${authorizationEndpoint}?response_type=code&display=popup&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join(
              scopeDelimiter
            )}&config_id=${configId}`;
            window.open(
              authUrl,
              "Facebook authentication",
              "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
            );
            break;

          case "instagram":
            authorizationEndpoint =
              "https://www.facebook.com/v18.0/dialog/oauth";
            redirectUri = "https://akindervolunteer.com/instagram-callback";
            scope = [
              "instagram_basic",
              "instagram_content_publish",
              "instagram_manage_comments",
              "instagram_manage_insights",
              "pages_show_list",
              "pages_read_engagement",
              "read_insights",
              "pages_manage_posts",
            ];
            scopeDelimiter = ",";
            configId = process.env.VUE_APP_INSTAGRAM_CONFIGURATION_ID;
            width = 564.348;
            height = 670;
            left = window.innerWidth / 2 - width / 2;
            top = window.innerHeight / 2 - height / 2;
            clientId = process.env.VUE_APP_FACEBOOK_ID;
            extras = `{"setup":{"channel":"IG_API_ONBOARDING"}}`;
            authUrl = `${authorizationEndpoint}?response_type=code&display=popup&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join(
              scopeDelimiter
            )}&config_id=${configId}&extras=${extras}`;
            window.open(
              authUrl,
              "Instagram authentication",
              "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
            );
            break;

          case "linkedin":
            authorizationEndpoint =
              "https://www.linkedin.com/oauth/v2/authorization";
            redirectUri = "https://akindervolunteer.com/linkedin-callback";
            scope = ["openid", "profile", "email", "w_member_social"];
            scopeDelimiter = " ";
            state = "STATE";
            width = 564.348;
            height = 670;
            left = window.innerWidth / 2 - width / 2;
            top = window.innerHeight / 2 - height / 2;
            clientId = process.env.VUE_APP_LINKEDIN_ID;
            authUrl = `${authorizationEndpoint}?response_type=code&display=popup&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join(
              scopeDelimiter
            )}&state=${state}`;
            window.open(
              authUrl,
              "LinkedIn authentication",
              "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
            );
            break;

          case "twitter":
            this.requestTwitterToken()
              .then((res) => {
                console.log(res);
                authorizationEndpoint =
                  "https://api.twitter.com/oauth/authenticate";
                redirectUri = "https://akindervolunteer.com/twitter-callback";
                width = 800;
                height = 670;
                left = window.innerWidth / 2 - width / 2;
                top = window.innerHeight / 2 - height / 2;
                authUrl = `${authorizationEndpoint}?oauth_token=${res}`;
                window.open(
                  authUrl,
                  "Twitter authentication",
                  "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                    width +
                    ", height=" +
                    height +
                    ", top=" +
                    top +
                    ", left=" +
                    left
                );
              })
              .catch((error) => {
                console.log(error.response.data.message);
              });
            /**
             * Twitter OAuth 2.0
             */
            // authorizationEndpoint = "https://twitter.com/i/oauth2/authorize";
            // redirectUri = "https://akindervolunteer.com/twitter-callback";
            // scope = [
            //   "tweet.read",
            //   "tweet.write",
            //   "users.read",
            //   "follows.read",
            //   "follows.write",
            // ];
            // scopeDelimiter = " ";
            // state = "state";
            // width = 564.348;
            // height = 670;
            // left = window.innerWidth / 2 - width / 2;
            // top = window.innerHeight / 2 - height / 2;
            // clientId = process.env.VUE_APP_TWITTER_ID;
            // authUrl = `${authorizationEndpoint}?response_type=code&display=popup&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join(
            //   scopeDelimiter
            // )}&state=${state}&code_challenge=challenge&code_challenge_method=plain`;
            // window.open(
            //   authUrl,
            //   "Twitter authentication",
            //   "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            //     width +
            //     ", height=" +
            //     height +
            //     ", top=" +
            //     top +
            //     ", left=" +
            //     left
            // );
            break;

          default:
            console.log("unsupported provider");
            break;
        }
      }
    },
    formatCurrency(numberString) {
      const number = parseFloat(`${numberString}`);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    onConnectBalance() {
      this.connectingBalance = true;
      this.connectAgencyBalance()
        .then(() => {
          this.connectingBalance = false;
          location.reload();
        })
        .catch((error) => {
          this.connectingBalance = false;
          this.showSnackMessage(error.response.data.message);
        });
    },
    goToDonations() {
      this.$router.push({
        name: "donation-history",
        query: { filter: "received" },
      });
    },
    goToOnboarding() {
      this.connectingBalance = true;
      this.getOnboardingUrl()
        .then((res) => {
          this.connectingBalance = false;
          console.log(res);
          window.open(res.url, "_blank");
        })
        .catch((error) => {
          this.connectingBalance = false;
          this.showSnackMessage(error.response.data.message);
        });
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
      profileType: "getType",
    }),
  },
  watch: {
    profile(newValue) {
      this.email = newValue.email;
      this.phone = newValue.phone;
      this.profile_share_privacy = newValue.profile_share_privacy;
      if (!this.profile_share_privacy) this.profile_share_privacy = "Only me";

      if (newValue.stripe_account) {
        this.getBalance()
          .then((res) => {
            this.balance = res;
            console.log(res);
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    this.email = this.profile.email;
    this.phone = this.profile.phone;
    this.profile_share_privacy = this.profile.profile_share_privacy;
    if (!this.profile_share_privacy) this.profile_share_privacy = "Only me";

    this.socialConnectedChannel.onmessage = (event) => {
      this.showSnackMessage(event.data);
      location.reload();
    };

    if (this.profile.stripe_account) {
      this.getBalance()
        .then((res) => {
          this.balance = res;
          console.log(res);
        })
        .catch((error) => console.log(error));
    }
  },
  beforeDestroy() {
    // Close the channel and remove the event listener when the component is destroyed
    this.socialConnectedChannel.close();
  },
};
</script>
