import { render, staticRenderFns } from "./SettingLeftSection.vue?vue&type=template&id=0296ce5b&scoped=true&"
import script from "./SettingLeftSection.vue?vue&type=script&lang=js&"
export * from "./SettingLeftSection.vue?vue&type=script&lang=js&"
import style1 from "./SettingLeftSection.vue?vue&type=style&index=1&id=0296ce5b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0296ce5b",
  null
  
)

export default component.exports