<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20 mb-5 white--text">
      {{ $t("language_settings_title") }}
    </div>
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate color="amber" class="mt-10" />
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel
        class="mb-2 rounded-lg"
        v-for="item in menuList"
        :key="item.key"
      >
        <v-expansion-panel-header>
          <template>
            <div class="app-bold-font font-16">
              {{ item.title }}
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="font-14">
            {{ item.description }}
          </div>
          <v-divider class="my-2" />
          <div>
            <div
              class="font-14 app-bold-font d-flex mt-2 justify-between align-center"
            >
              <v-autocomplete
                :items="item.list"
                v-if="item.key == 'timezone'"
                :filter="searchFilter"
                v-model="item.value"
                item-text="name"
                item-value="name"
                @input="onChange(item)"
              >
                <template v-slot:selection="data">
                  UTC{{ data.item.currentTimeFormat }}
                </template>
                <template v-slot:item="data">
                  UTC{{ data.item.currentTimeFormat }}
                </template>
              </v-autocomplete>
              <v-autocomplete
                :items="item.list"
                v-model="item.value"
                v-else
                @input="onChange(item)"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getTimeZones } from "@vvo/tzdb";

export default {
  data() {
    return {
      menuList: [
        {
          title: "Language",
          description: "Language of title, text of aKinder™Volunteer",
          key: "language",
          list: ["English", "Spanish", "Portuguese"],
        },
        {
          title: "Timezone",
          description: "Please select your timezone.",
          key: "timezone",
          list: getTimeZones(),
        },
        {
          title: "Unit",
          description:
            "Please select your unit system of temperature or measurement",
          key: "unit",
          list: ["Imperial", "Metric"],
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions("usersetting", {
      getSettings: "getRegionSettings",
      setSetting: "setRegionSetting",
    }),
    onChange(item) {
      this.setSetting({ key: item.key, value: item.value });
    },
    searchFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (item.currentTimeFormat.toLowerCase().includes(searchText)) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      language: "usersetting/getLanguage",
    }),
  },
  watch: {
    language(newValue) {
      if (newValue) {
        this.$i18n.locale = newValue;
      }
    },
  },
  mounted() {
    console.log(this.menuList);
    this.loading = true;
    this.getSettings()
      .then((res) => {
        this.loading = false;
        res.map((r, index) => {
          this.menuList[index] = { ...this.menuList[index], value: r };
        });
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>
