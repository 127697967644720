<template>
  <div class="w-100">
    <div class="app-bold-font d-flex align-start font-20 white--text">
      {{ $t("site_settings") }}
    </div>
    <v-alert class="mt-5" type="warning">
      Danger Zone! Please don't change the icons if you are not familiar with
      it. Please contact the developer if you have any questions.
    </v-alert>
    <v-expansion-panels class="mt-5">
      <v-expansion-panel class="mb-2 rounded-lg">
        <v-expansion-panel-header>
          <template>
            <div class="d-flex justify-between">
              <div class="app-bold-font font-16">
                Causes
              </div>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-row>
              <v-col
                cols="3"
                v-for="item in causes"
                :key="item._id"
                class="pa-2"
              >
                <v-card @click="selectIcon(item, 'Cause')" class="rounded-lg">
                  <v-card-text
                    class="d-flex flex-column justify-center align-center py-5"
                  >
                    <site-icon :name="item.name" type="Cause" />
                    <span
                      style="font-size: 12px; margin-top: 5px;color:#242f36"
                      >{{ item.name }}</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3" class="pa-2">
                <v-card @click="addIcon('Cause')" class="rounded-lg">
                  <v-card-text
                    class="d-flex flex-column justify-center align-center py-5"
                  >
                    <v-img
                      :src="require('@/assets/svg/add-icon.svg')"
                      width="60"
                      height="60"
                      contain
                    />
                    <span style="font-size: 12px; margin-top: 5px;color:#242f36"
                      >Add Icon</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2 rounded-lg">
        <v-expansion-panel-header>
          <template>
            <div class="app-bold-font font-16">
              Interests
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-row>
              <v-col
                cols="3"
                v-for="item in interests"
                :key="item._id"
                class="pa-2"
              >
                <v-card
                  @click="selectIcon(item, 'Interest')"
                  class="rounded-lg"
                >
                  <v-card-text
                    class="d-flex flex-column justify-center align-center py-5"
                  >
                    <site-icon :name="item.name" type="Interest" />
                    <span
                      style="font-size: 12px; margin-top: 5px;color:#242f36"
                      >{{ item.name }}</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3" class="pa-2">
                <v-card @click="addIcon('Interest')" class="rounded-lg">
                  <v-card-text
                    class="d-flex flex-column justify-center align-center py-5"
                  >
                    <v-img
                      :src="require('@/assets/svg/add-icon.svg')"
                      width="60"
                      height="60"
                      contain
                    />
                    <span style="font-size: 12px; margin-top: 5px;color:#242f36"
                      >Add Icon</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2 rounded-lg">
        <v-expansion-panel-header>
          <template>
            <div class="app-bold-font font-16">
              AI Support Initial Data
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-textarea
              label="Initial AI Support Data, Should be clear and shorter as much as possible."
              solo
              flat
              outlined
              class="mt-2 font-12"
              v-model="chatDataText"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
              rows="3"
            />
            <v-btn
              color="#0a5b8a"
              @click="onSaveChatData"
              class="white--text"
              width="150"
              :loading="loading"
              >Save</v-btn
            >
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <site-icon-add
      :onClose="onCloseDialog"
      :type="dialogType"
      :dialog="addDialog"
      v-if="addDialog"
    />
    <site-icon-edit
      :onClose="onCloseDialog"
      :type="dialogType"
      :icon="selected"
      :dialog="editDialog"
      v-if="editDialog && selected"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SiteIconAdd from "./SiteIconAdd.vue";
import SiteIconEdit from "./SiteIconEdit.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";

export default {
  components: { SiteIconAdd, SiteIcon, SiteIconEdit },
  data() {
    return {
      loading: false,
      addDialog: false,
      editDialog: false,
      dialogType: "",
      selected: null,
      chatDataText: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    ...mapActions({
      getCauseIcons: "icon/getCauseIcons",
      getInterestIcons: "icon/getInterestIcons",
      setChatbotData: "usersetting/setChatbotData",
      getChatbotData: "usersetting/getChatbotData",
    }),
    addIcon(type) {
      this.dialogType = type;
      this.addDialog = true;
    },
    onCloseDialog() {
      this.addDialog = false;
      this.editDialog = false;
    },
    selectIcon(item, type) {
      this.dialogType = type;
      this.selected = item;
      this.editDialog = true;
    },
    onSaveChatData() {
      if (this.chatDataText.length) {
        this.loading = true;
        this.setChatbotData({ value: this.chatDataText })
          .then((res) => {
            console.log(res);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err.response.data.message);
          });
      }
    },
  },
  computed: {
    ...mapState("icon", {
      interests: "interests",
      causes: "causes",
    }),
  },
  mounted() {
    this.loading = true;
    this.getCauseIcons()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
    this.getInterestIcons()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });

    this.getChatbotData()
      .then((res) => {
        this.chatDataText = res.value;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.chatDataText = "";
      });
  },
};
</script>
